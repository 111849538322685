import {
  IdentityProvider,
  SocialFederationButton,
  SocialFederationButtonProps,
} from '@/components/atoms/legacy/SocialFederationButton/SocialFederationButton';
import EmailFederationButton from '../EmailFederationButton/EmailFederationButton';

const SUPPORTED_PROVIDERS: Readonly<IdentityProvider[]> = [
  'google',
  'apple',
  'microsoft',
  'facebook',
];

interface SocialFederationOptionsProps
  extends Pick<
    SocialFederationButtonProps,
    'sendAnalytics' | 'onSuccess' | 'onFailure' | 'actionType'
  > {
  handleSignInAction?: () => void;
  emailContinueText?: boolean;
}

export const SocialFederationOptions = ({
  handleSignInAction,
  onSuccess,
  onFailure,
  sendAnalytics,
  actionType,
  emailContinueText,
}: SocialFederationOptionsProps) => {
  return (
    <div className="flex w-full flex-col items-center gap-l">
      <div className="flex w-full flex-col gap-l">
        {SUPPORTED_PROVIDERS.map((provider) => (
          <SocialFederationButton
            key={provider}
            identityProvider={provider}
            actionType={actionType}
            preHook={handleSignInAction}
            onSuccess={onSuccess}
            onFailure={onFailure}
            sendAnalytics={sendAnalytics}
          />
        ))}
      </div>
      <div className="flex w-full items-center gap-l">
        <div className="h-px grow rounded-xsmall bg-neutral-300" />
        <div className="text-body1Regular text-neutral-600">OR</div>
        <div className="h-px grow rounded-xsmall bg-neutral-300" />
      </div>
      <EmailFederationButton
        actionType={actionType}
        preHook={handleSignInAction}
        continueText={emailContinueText}
        sendAnalytics={sendAnalytics}
      />
    </div>
  );
};
