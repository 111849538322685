import { Dialog } from '@/components/molecules/Dialog';
import { SocialFederationOptions } from '@/components/molecules/SocialFederationOptions/SocialFederationOptions';
import { AFTER_LOGIN_REDIRECT_KEY } from '@/lib/constants';
import { useRouter } from 'next/router';
import Image from '@/components/atoms/Image';
import { ReactNode } from 'react';

export interface AuthDialogProps {
  title: string;
  description: string;
  isAuthDialogOpen: boolean;
  setIsAuthDialogOpen: (isOpen: boolean) => void;
  backGroundImage?: string;
  renderIcon: ReactNode;
}

export const AuthDialog: React.FC<AuthDialogProps> = ({
  title,
  description,
  isAuthDialogOpen,
  setIsAuthDialogOpen,
  backGroundImage = null,
  renderIcon,
}) => {
  const router = useRouter();
  return (
    <Dialog
      open={isAuthDialogOpen}
      onOpenChange={() => setIsAuthDialogOpen(!isAuthDialogOpen)}
      contentClassName="l:min-w-[592px]"
    >
      <div className="flex flex-col gap-l p-xl">
        <div className="self-start">
          <span className="text-body2Light text-neutral-800">{title}</span>
        </div>
        <div className="flex items-center self-center">
          {backGroundImage && (
            <div className="h-[80px] w-[80px] rounded-full border-[2px] border-brandMidnight">
              <Image
                src={backGroundImage}
                alt="background image"
                fill
                className="h-full w-full rounded-full object-cover"
              />
            </div>
          )}
          <div className="absolute left-[54%] flex h-[48px] w-[48px] items-center justify-center rounded-full border-[1px] border-neutral-600 bg-white">
            <div className="flex">{renderIcon}</div>
          </div>
        </div>
        <div className="self-center">
          <span className="text-body1Regular text-neutral-800">
            {description}
          </span>
        </div>
        <SocialFederationOptions
          actionType="login"
          handleSignInAction={() => {
            sessionStorage.setItem(AFTER_LOGIN_REDIRECT_KEY, router.asPath);
          }}
        />
      </div>
    </Dialog>
  );
};
